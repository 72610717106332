<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="create.name" v-validate="'required'" />
      </div>
    </div>
    <br>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Promo Coa</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect v-model="create.MappingGlStruct.PromotionFeeCoaSelected" :options="PromotionFeeCoaOption"
          :max-height="125" :multiple="false" :group-select="false" :limit="3" placeholder="Type to search"
          @search-change="getPromotionCoa" :loading="isLoading" :internal-search="false">
          <!-- <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="PromotionFeeCoaOption.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
          </template> -->
          <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          <!-- <span slot="noResult">Oops! No data found</span> -->
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.coa.Code }} - {{ props.option.coa.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.coa.Code }} - {{ props.option.coa.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <br>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Withholding Tax Coa</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect v-model="create.MappingGlStruct.WithholdingTaxCoaSelected" :options="WithholdingTaxCoaOption"
          :max-height="125" :group-select="false" :limit="3" placeholder="Type to search"
          @search-change="getWithholdingTaxCoa" :loading="isLoading" :internal-search="false">
          <!-- <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="WithholdingTaxCoaOption.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
          </template> -->
          <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.coa.Code }} - {{ props.option.coa.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.coa.Code }} - {{ props.option.coa.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Withholding Tax Item</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect v-model="create.MappingGlStruct.ItemTaxSelected" :options="ItemTaxOption" :max-height="125"
          :multiple="false" :group-select="false" :limit="3" placeholder="Type to search">
          <!-- <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="PromotionFeeCoaOption.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
          </template> -->
          <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
          <!-- <span slot="noResult">Oops! No data found</span> -->
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }} - {{ props.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }} - {{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <br>
    <template v-for="(rate, i) in rateOption ">
      <div v-bind:key="rate" class="vx-row margin-btm mb-2" style="width: 100%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Withholding Tax Rate <br>(NPWP Type {{ rate }}) </span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input class="w-full" @keypress="NumbersOnly($event)" v-model="create.rate[i]" v-validate="'required'" />
        </div>
      </div>


    </template>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'process';

export default {
  props: {
    url: {
      type: String
    },
    data: {
      type: Object
    },
    id: {
      type: Number
    },
  },
  data() {
    return {
      // 1: Real Account,2: VA, 3: Wallet
      rateOption: ["Personal", "Company", "Non Pkp"],
      PromotionFeeCoaOption: [],
      WithholdingTaxCoaOption: [],
      ItemTaxOption: [],
      isLoading: false,
      create: {
        ID: 0,
        name: '',
        MappingGlStruct: {
          PromotionFeeCoa: '',
          PromotionFeeCoaID: 0,
          PromotionFeeCoaSelected: null,
          PromotionFeeCoaText: '',
          WithholdingTaxCoa: '',
          WithholdingTaxCoaID: 0,
          WithholdingTaxCoaSelected: null,
          WithholdingTaxCoaText: '',
          ItemTaxID: 0,
          ItemTaxCode: '',
          ItemTaxSelected: null,
          ItemTaxName: '',
        },
        RateOption: [],
        rate: [],

      },

    };
  },
  mounted() {
    this.getItemTax();
    // for(let i =0; i < this.$refs.file.files.length; i++) {

    //       th
    this.create.rate = []
    this.rateOption.map(() => {
      this.create.rate.push(0)
    })
    if (this.id != 0) {
      this.create.name = this.data.Name
      this.create.ID = this.data.ID
      // this.create.MappingGlStruct = this.data.MappingGl[0]
      // this.create.MappingGlStruct.PromotionFeeCoaSelected = this.data.MappingGl[0]
      // this.create.MappingGlStruct.PromotionFeeCoaID = this.create.MappingGlStruct.PromotionFeeCoaSelected.coa.ID
      this.create.MappingGlStruct.PromotionFeeCoaSelected = {
        coa: {
          ID: this.data.MappingGl[0].PromotionFeeCoaID,
          Name: this.data.MappingGl[0].PromotionFeeCoaName,
          Code: this.data.MappingGl[0].PromotionFeeCoa,
        }
      }
      this.create.MappingGlStruct.WithholdingTaxCoaSelected = {
        coa: {
          ID: this.data.MappingGl[0].WithholdingTaxCoaID,
          Name: this.data.MappingGl[0].WithholdingTaxCoaName,
          Code: this.data.MappingGl[0].WithholdingTaxCoa,
        }
      }
      this.create.MappingGlStruct.ItemTaxSelected = {
        item_unit_id: this.data.MappingGl[0].ItemTaxID,
        code: this.data.MappingGl[0].ItemTaxCode,
        name: this.data.MappingGl[0].ItemTaxName,
      }
      this.create.rate = []
      this.rateOption.map((v) => {
        // this.create.rate.push(0)
        const check = this.data.RateOption.filter(subv => {
          console.log(subv, 'subv', v)
          return subv.NpwpType == v
        })
        console.log(check, 'check')
        if (check.length > 0) {
          this.create.rate.push(check[0].Rate)
        } else {
          this.create.rate.push(0)
        }
      })
    }
  },
  watch: {
    "data.ID"() {
      this.create.name = this.data.Name
      this.create.ID = this.data.ID
      // this.create.MappingGlStruct = this.data.MappingGl[0]
      // this.create.MappingGlStruct.PromotionFeeCoaSelected = this.data.MappingGl[0]
      // this.create.MappingGlStruct.PromotionFeeCoaID = this.create.MappingGlStruct.PromotionFeeCoaSelected.coa.ID
      this.create.MappingGlStruct.PromotionFeeCoaSelected = {
        coa: {
          ID: this.data.MappingGl[0].PromotionFeeCoaID,
          Name: this.data.MappingGl[0].PromotionFeeCoaName,
          Code: this.data.MappingGl[0].PromotionFeeCoa,
        }
      }
      this.create.MappingGlStruct.WithholdingTaxCoaSelected = {
        coa: {
          ID: this.data.MappingGl[0].WithholdingTaxCoaID,
          Name: this.data.MappingGl[0].WithholdingTaxCoaName,
          Code: this.data.MappingGl[0].WithholdingTaxCoa,
        }
      }
      this.create.MappingGlStruct.ItemTaxSelected = {
        item_unit_id: this.data.MappingGl[0].ItemTaxID,
        code: this.data.MappingGl[0].ItemTaxCode,
        name: this.data.MappingGl[0].ItemTaxName,
      }
      this.create.rate = []
      this.rateOption.map((v) => {
        // this.create.rate.push(0)
        const check = this.data.RateOption.filter(subv => {
          console.log(subv, 'subv', v)
          return subv.NpwpType == v
        })
        console.log(check, 'check')
        if (check.length > 0) {
          this.create.rate.push(check[0].Rate)
        } else {
          this.create.rate.push(0)
        }
      })

    },
    id() {
      console.log(this.data)
      if (this.id) {
        // this.getData();
        this.create.name = this.data.Name
        this.create.ID = this.data.ID
        // this.create.MappingGlStruct = this.data.MappingGl[0]
        // this.create.MappingGlStruct.PromotionFeeCoaSelected = this.data.MappingGl[0]
        // this.create.MappingGlStruct.PromotionFeeCoaID = this.create.MappingGlStruct.PromotionFeeCoaSelected.coa.ID
        this.create.MappingGlStruct.PromotionFeeCoaSelected = {
          coa: {
            ID: this.data.MappingGl[0].PromotionFeeCoaID,
            Name: this.data.MappingGl[0].PromotionFeeCoaName,
            Code: this.data.MappingGl[0].PromotionFeeCoa,
          }
        }
        this.create.MappingGlStruct.WithholdingTaxCoaSelected = {
          coa: {
            ID: this.data.MappingGl[0].WithholdingTaxCoaID,
            Name: this.data.MappingGl[0].WithholdingTaxCoaName,
            Code: this.data.MappingGl[0].WithholdingTaxCoa,
          }
        }
        this.create.MappingGlStruct.ItemTaxSelected = {
          item_unit_id: this.data.MappingGl[0].ItemTaxID,
          code: this.data.MappingGl[0].ItemTaxCode,
          name: this.data.MappingGl[0].ItemTaxName,
        }
        this.create.rate = []
        this.rateOption.map((v) => {
          // this.create.rate.push(0)
          const check = this.data.RateOption.filter(subv => {
            console.log(subv, 'subv', v)
            return subv.NpwpType == v
          })
          console.log(check, 'check')
          if (check.length > 0) {
            this.create.rate.push(check[0].Rate)
          } else {
            this.create.rate.push(0)
          }
        })
      } else {
        this.create = {
          accountNumber: null,
          accountName: null,
          customer: null,
          typePayment: null,
          issuer: null,
          notes: null,
          id: null,
          type: null,
          customerId: null,
          bankId: null,
          bankBranch: null,
          swiftCode: null,
        }
      }
    }
  },
  methods: {
    
    closeDetail() {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getTimestampInSeconds() {
      return Math.floor(Date.now() / 1000)
    },
    handleSubmit() {
      console.log(this.create)
      if (this.create.name == '') {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Name required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }

      if (this.create.MappingGlStruct.ItemTaxSelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Withholding tax item can't empty",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }

      if (this.create.MappingGlStruct.WithholdingTaxCoaSelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Withholding tax coa can't empty",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }

      if (this.create.MappingGlStruct.PromotionFeeCoaSelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Promotion fee coa can't empty",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }

      let inputRate = false
      this.create.RateOption = []
      this.rateOption.map((v, i) => {
        const option = {
          'ListID': this.getTimestampInSeconds(),
          'Rate': parseInt(this.create.rate[i]),
          'NpwpType': v
        }
        if (this.create.rate[i] > 0 && this.create.rate[i] < 100) {
          inputRate = true
          this.create.RateOption.push(option)
        }
        this.create.rate.push(0)
      })
      if (!inputRate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Rate must be filled in at least one ",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }

      this.rateOption.map((v, i) => {
        if (this.create.rate[i] > 100) {
          inputRate = false
        }

      })
      if (!inputRate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Rate must be less then 100 ",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }
      this.create.MappingGlStruct.ListID = this.getTimestampInSeconds()
      this.create.MappingGlStruct.PromotionFeeCoaID = this.create.MappingGlStruct.PromotionFeeCoaSelected.coa.ID
      this.create.MappingGlStruct.PromotionFeeCoa = this.create.MappingGlStruct.PromotionFeeCoaSelected.coa.Code
      this.create.MappingGlStruct.PromotionFeeCoaName = this.create.MappingGlStruct.PromotionFeeCoaSelected.coa.Name
      this.create.MappingGlStruct.WithholdingTaxCoaID = this.create.MappingGlStruct.WithholdingTaxCoaSelected.coa.ID
      this.create.MappingGlStruct.WithholdingTaxCoa = this.create.MappingGlStruct.WithholdingTaxCoaSelected.coa.Code
      this.create.MappingGlStruct.WithholdingTaxCoaName = this.create.MappingGlStruct.WithholdingTaxCoaSelected.coa.Name
      this.create.MappingGlStruct.ItemTaxID = this.create.MappingGlStruct.ItemTaxSelected.item_unit_id
      this.create.MappingGlStruct.ItemTaxCode = this.create.MappingGlStruct.ItemTaxSelected.code
      this.create.MappingGlStruct.ItemTaxName = this.create.MappingGlStruct.ItemTaxSelected.name

      // 
      const params = {
        id: this.create.ID,
        name: this.create.name,
        mapping_gl: [this.create.MappingGlStruct],
        rate_option: this.create.RateOption,
      }
      this.$vs.loading();
      //console.log(params)
      this.$vs.loading();

      this.$http.post('/api/v1/credit-note-promotion-type', params)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$emit("closeDetail", true);
          this.$vs.loading.close();

        })
    },
    formatNumber(string) {
      if (string) {
        return parseFloat(string.toString().replace(/\./g, ""));
      } else {
        return 0;
      }
    },
    getPromotionCoa(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.isLoading = true
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "name",
            sort: "asc",
            search: query
          },
        })
        .then((resp) => {
          this.isLoading = false
          console.log(resp)
          if (resp.status == "success") {
            this.PromotionFeeCoaOption = resp.data.records
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWithholdingTaxCoa(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "name",
            sort: "asc",
            search: query
          },
        })
        .then((resp) => {
          console.log(resp)
          if (resp.status == "success") {
            this.WithholdingTaxCoaOption = resp.data.records
            
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getItemTax() {
      this.$vs.loading();
      this.$vs.loading();
      this.$http
        .get("/api/v1/credit-note-promotion-type/item-type-tax")
        .then((resp) => {
          console.log(resp)
          if (resp.status == "success") {
            this.ItemTaxOption = resp.data.ItemsAvailable
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    
  },
};
</script>